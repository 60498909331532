<template>
  <v-container class="px-1 pt-0">
    <!-- Bild-karusellen -->
    <v-carousel v-if="$vuetify.breakpoint.lgAndDown" cycle height="50vh" :show-arrows="false" hide-delimiters>
      <v-carousel-item v-for="(url, i) in urls" :key="i" :src="url" />
    </v-carousel>
    <!-- För breda skärmar (pga liten styrelsebild) -->
    <v-carousel v-if="$vuetify.breakpoint.xl && !isDark" cycle height="50vh" :show-arrows="false" hide-delimiters>
      <v-carousel-item v-for="(url, i) in urls_xl_light" :key="i" :src="url" />
    </v-carousel>
    <v-carousel v-if="$vuetify.breakpoint.xl && isDark" cycle height="50vh" :show-arrows="false" hide-delimiters>
      <v-carousel-item v-for="(url, i) in urls_xl_dark" :key="i" :src="url" />
    </v-carousel>

    <!-- Snabb-navigering -->
    <v-container class="pa-3">
      <v-row class="px-1">
        <v-col
          cols="4"
          class="px-1 py-0"
          v-for="linkData in getLinkData"
          :key="linkData.path"
        >
          <!-- Fyrkantiga knappar för större skärmar -->
          <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            :to="linkData.path"
            block
            outlined
            class="primary--text"
            x-large
          >
            <v-icon left>{{ linkData.icon }}</v-icon>
            <textloader-component :textKey="linkData.path" />
          </v-btn>
          <!-- Runda knappar för små skärmar -->
          <v-row v-if="$vuetify.breakpoint.smAndDown">
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                :to="linkData.path"
                fab
                class="btn--navigation primary--text"
              >
                <v-icon>{{ linkData.icon }}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" class="text-center pt-0">
              <textloader-component :textKey="linkData.path" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <v-btn
            block
            outlined
            class="primary--text"
            x-large
            href="https://docs.google.com/forms/d/e/1FAIpQLSciRk6c_CtJbuTJhZUVgcoK0rPiEwnChwdgnQby9P4Ck-oiXA/viewform"
            target="_blank"
          >
            <v-icon left>mdi-hand-heart</v-icon>
            Trygghetsformuläret!
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <v-btn
            block
            outlined
            class="primary--text"
            x-large
            to="/forslagslada"
          >
            <v-icon left>mdi-vote</v-icon>
            <textloader-component textKey="/forslagslada"/>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            block
            outlined
            class="primary--text"
            x-large
            href="http://eepurl.com/gi8Xir"
            target="_blank"
          >
            <v-icon left>mdi-email</v-icon>
            FUTFs nyhetsbrev!
          </v-btn>
        </v-col>
      </v-row>

    </v-container>

    <v-divider />

    <!-- Flöde -->
    <v-container class="pt-0 px-2">
      <v-row>
        <v-col
          v-if="
            $vuetify.breakpoint.xsOnly ||
            $vuetify.breakpoint.smOnly ||
            $vuetify.breakpoint.mdOnly
          "
          cols="12"
        >
          <infocards />
        </v-col>
        <v-col :cols="12" v-for="(post, i) in posts" :key="i">
          <newscard
            v-bind="post"
            :language="currentLang"
          ></newscard>
        </v-col>
        <v-col
          v-if="
            $vuetify.breakpoint.xsOnly ||
            $vuetify.breakpoint.smOnly ||
            $vuetify.breakpoint.mdOnly
          "
          cols="12"
        >
          <sponsorlist />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import newscard from '@/components/Newscard.vue'
import sponsorlist from '@/components/SponsorComponent.vue'
import infocards from '@/components/InfoCardComponent.vue'
import navdata from '@/assets/navdata.json'
import TextloaderComponent from '@/components/TextloaderComponent'
import axios from 'axios'

export default {
  name: 'Home',
  components: {
    newscard,
    sponsorlist,
    infocards,
    TextloaderComponent
  },
  data: () => {
    return {
      // Lista med bild-länkar
      urls: [
        require('@/assets/styrelsebilder/styrelsen.jpg'),
        require('@/assets/Home/AngstromFramsida.jpeg'),
        'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'
      ],
      urls_xl_light: [
        require('@/assets/styrelsebilder/styrelsen_xl_light.jpg'),
        require('@/assets/Home/AngstromFramsida.jpeg'),
        'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'
      ],
      urls_xl_dark: [
        require('@/assets/styrelsebilder/styrelsen_xl_dark.jpg'),
        require('@/assets/Home/AngstromFramsida.jpeg'),
        'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'
      ],
      posts: [],
      // Lista med inlägg till nyhetsflödet
      // Lista med sidor att ha snabbnavigering till
      quacklings: [
        // (quick-links)
        '/kalender',
        '/schema',
        '/engageradig'
      ]
    }
  },
  computed: {
    isDark: function () {
      return this.$vuetify.theme.dark
    },
    currentLang: function () {
      return this.$store.getters.lang
    },
    getLinkData: function () {
      // Read navigation list form 'pageinfo.json'
      const data = []

      // Find target page, and insert correct path and icon
      for (const linkname of this.quacklings) {
        const page = navdata.pageinfo.find((p) => p.path === linkname)
        if (page !== undefined) {
          data.push({ path: page.path, icon: page.icon })
        }
      }
      return data
    }
  },
  created () {
    axios
      .get('/api/events/all')
      .then((result) => {
        // Det som är senast tillagt i DB:n ska visas först
        result.data.reverse()
        this.posts = result.data
      })
  }
}
</script>
